<script setup>
import Particles from "./Particles.vue";
import logoComponent from '@/assets/duck_empire_logo2Asset 17.svg'
</script>

<template>
  <div class="site_wrapper">

    <div class="hero">
      <div class="hero-bg"></div>
      <Particles/>
      <div class="hero-fade"></div>
      <img class="emperor" src="@/assets/theemperor_v4_reflection.png" alt="Ducktator">
      <logoComponent class="logo"></logoComponent>
    </div>


    <div class="showcase">

      <div class="card">
        <a href="https://www.rustalyzer.com" target="_blank">
          <img class="card-logo" src="https://www.rustalyzer.com/_static/logo_dark.svg" alt="logo of Army Of Ducks">
          <h3>RUSTalyzer</h3>
          <img class="card-screen" src="@/assets/rustalyzer.png" alt="screenshot of Army Of Ducks">
          <p class="copy">Find the perfect Rust Server with <b>RUSTalyzer</b>, a powerful Rust Server Browser and Information Tool.</p>
        </a>
      </div>
      <div class="card">
        <a href="https://www.armyofducks.com" target="_blank">
          <img class="card-logo" src="https://www.armyofducks.com/img/army.svg" alt="logo of Army Of Ducks">
          <h3>Army Of Ducks</h3>
          <img class="card-screen" src="@/assets/screenshot_army2.png" alt="screenshot of Army Of Ducks">
          <p class="copy">Inspect the <b>Army Of Ducks</b> and add your own if you want.</p>
        </a>
      </div>
      <div class="card">
        <a href="https://www.empireoftheduck.com" target="_blank">
          <img class="card-logo" src="/duck_empire_wings.svg" alt="logo of Empire Of The Duck">
          <h3>Empire Of The Duck</h3>
          <img class="card-screen" src="@/assets/screenshot_empire.png" alt="screenshot of Empire Of The Duck">
          <p class="copy">Find out more about the Empire.</p>
        </a>
      </div>
      <div class="card">
        <a href="https://chat.empireoftheduck.com" target="_blank">
          <img class="card-logo" src="/duckgpt.svg" alt="logo of DuckGPT">
          <h3>DuckGPT</h3>
          <img class="card-screen" src="@/assets/screenshot_gpt.png" al DuckGPTt="screenshot of">
          <p class="copy">Have a quick chat with this advanced duck A.I.!</p>
        </a>
      </div>
      <div class="card">
        <a href="https://www.gameofducks.com" target="_blank">
          <img class="card-logo" src="/duck_empire_duckhead.svg" alt="logo of Double Ducky">
          <h3>Game Of Ducks</h3>
          <img class="card-screen" src="@/assets/screenshot_dd.png" alt="screenshot of Double Ducky">
          <p class="copy">Enjoy a game of "Double Ducky" or "Single Out".</p>
        </a>
      </div>

    </div>


    <div style="display: none;">
      <div class="intro">
        <!-- <h1>Empire of the Duck</h1> -->


        <h1>In the time before time, there was only the void.</h1>
        <p class="lead">But from the void, there emerged a mighty force - a force that would shape the destiny of the universe for all time to come. This force was <i>the Duck</i>.</p>
        <p>Born of the primordial waters of the cosmos, the Duck was a creature of great power and wisdom. It soared through the heavens and delved into the deepest depths of the universe, exploring all that lay before it.</p>
        <p>And so it was that the Duck came to rule over all that it surveyed. Its subjects were countless, its armies invincible. From the furthest reaches of the galaxy to the depths of the void, the Empire of the Duck reigned supreme.</p>
<!--        <p>But with great power came great responsibility. For the Duck knew that it must use its strength for the greater good, to protect and nurture its subjects and ensure their prosperity for all time. And so it was that the Empire of the Duck became a beacon of hope and a bastion of peace in a universe beset by chaos and darkness.</p>-->
        <p>The Empire became a beacon of hope and a bastion of peace in a universe beset by chaos and darkness.</p>
        <p>And though the ages passed and the stars grew old, the Empire of the Duck endured. For the Duck was eternal, and its empire would stand forever as a testament to the might and majesty of this most noble of creatures.</p>

<!--        <p>And so, let all who hear of the Empire of the Duck tremble with awe and reverence for the mighty power that we wield. Join us, and together we shall forge a glorious future, under the wise and just rule of the Duck. Long live the Empire of the Duck!</p>-->

        <div class="coin">
          <img src="@/assets/coin2.png" alt="coin">
        </div>

      </div>

    </div>


  </div>
</template>

<script>
export default {
  name: "Main",
  data() {
    return {
    }
  },
  methods: {

  }
}
</script>

<style>
.emperor {
  z-index: 2;
}
</style>